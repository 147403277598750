import React from 'react'

import * as Styles from './styles'

import leftArrow from './images/left-arrow.svg'
import rightArrow from './images/right-arrow.svg'

const PaginationArrow: React.FC<{
  which: 'left' | 'right'
  currentPage: number
  cyclePage: (pageNumber: number) => void
  className?: string
}> = ({ which, currentPage, cyclePage, className }) => (
  <Styles.ArrowHoverTarget which={which} className={className}>
    <Styles.ArrowCircleButton
      className="PaginationArrow__arrow-circle-button"
      onClick={() => cyclePage(currentPage + (which === 'left' ? -1 : 1))}
    >
      <Styles.Arrow src={which === 'left' ? leftArrow : rightArrow} />
    </Styles.ArrowCircleButton>
  </Styles.ArrowHoverTarget>
)

export default PaginationArrow
