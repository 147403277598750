import React from 'react'

import Dashboard from '@components/Dashboard'
import * as GoogleService from '@components/GoogleService'
import Layout from '@components/Layout'
import Login from '@components/GoogleService/Login'
import SEO from '@components/SEO'

const IndexPage = () => {
  if (!process.env.GATSBY_GOOGLE_CLIENT_ID) { return null }

  return (
    <Layout>
      <SEO title="Home" />

      <GoogleService.Consumer>
        {({ isLoading, showLogin, setUser }) =>
          showLogin ? <Login setUser={setUser} /> : <Dashboard isLoading={isLoading} />
        }
      </GoogleService.Consumer>
    </Layout>
  )
}

export default IndexPage
