import moment from 'moment-timezone'
import * as React from 'react'

import * as Styles from './styles'

interface Props {
  date: Date
  title: string
  url: string
  allDayEvent: boolean
  calendarID: string
}

const CalendarEvent = ({ date, title, url, allDayEvent, calendarID }: Props) => (
  <Styles.A calendarID={calendarID} href={url} target="_blank" rel="noopener">
    <Styles.TextWrapper>
      <Styles.Title title={title}>{title}</Styles.Title>
      {!allDayEvent && <Styles.Time>{moment(date).format('h:mm A')}</Styles.Time>}
    </Styles.TextWrapper>
  </Styles.A>
)

export default CalendarEvent
