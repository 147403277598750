import React from 'react'
import ReactMarkdown from 'react-markdown'

import * as Styles from './styles'

const StyledMarkdown: React.FC<{ source: string }> = ({ source }) => {
  let headingIndex = 1

  return (
    <ReactMarkdown
      source={source}
      renderers={{
        heading: ({ level, children }) => {
          switch (level) {
            case 1:
              return <Styles.H1>{children}</Styles.H1>
            case 2:
              return (
                <Styles.H2 id={`toc-heading-${headingIndex++}`}>
                  {children}
                </Styles.H2>
              )
            default:
              return <Styles.H3>{children}</Styles.H3>
          }
        },

        list: Styles.Ul,
        listItem: Styles.Li,

        table: Styles.Table,
        // We don't actually want to render the table header, but the Markdown
        // parser doesn't support tables without heaers. Thus, we'll just render
        // it as `null`.
        tableHead: () => null,
        tableCell: Styles.TableCell,

        paragraph: Styles.P,

        emphasis: props => (
          <em style={{ fontStyle: 'italic' }}>{props.children}</em>
        ),
        link: props => (
          <a
            style={{ textDecoration: 'underline', color: '#999' }}
            href={props.href}
            target="_blank"
          >
            {props.children}
          </a>
        ),
      }}
    />
  )
}

export default StyledMarkdown
