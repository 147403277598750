import styled from 'styled-components'

import { Card as CardBase } from '@lib/layout'

export const Card = styled(CardBase)`
  line-height: 1.5em;
  font-size: 0.875rem;
`

export const P = styled.p`
  letter-spacing: 0.3px;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1.5rem 0;
`

export const CaretWithHover = styled.span`
  display: inline-block;
  width: 20px;
  height: 10px;
  vertical-align: middle;

  background-image: url('data:image/svg+xml;utf8,<svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9L5 5L1 1" stroke="rgb(102,102,102)" stroke-width="1.5"/></svg>');
  background-repeat: no-repeat;
  background-position: right center;

  a:hover > & {
    background-image: url('data:image/svg+xml;utf8,<svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9L5 5L1 1" stroke="rgb(34,34,34)" stroke-width="1.5"/></svg>');
  }
`
