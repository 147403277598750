import styled, { css } from 'styled-components'

import { Breakpoint } from '@lib/layout'
import { Card as CardBase } from '@lib/layout'
import { CALENDAR_TOTAL_DAYS, CALENDAR_DAYS_PER_VIEW, CALENDAR_TOTAL_VIEWS, CALENDAR_MONTH_HEIGHT } from './constants'
import { getDotColorFromCalendarID } from './helpers';
import PaginationArrowBase from '@components/PaginationArrow';
import { ViewMoreLink as ViewMoreLinkBase } from '@lib/typography'

export const CalendarDot = css<{ calendarID: string }>`
  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${props => getDotColorFromCalendarID(props.calendarID)};
    margin-right: 6px;
    flex-shrink: 0;
  }
`

export const ViewMoreLink = styled(ViewMoreLinkBase)<{ calendarID: string }>`
  ${CalendarDot}
`

export const Card = styled(CardBase)`
  flex-grow: 0;

  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${Breakpoint.lg}px) {
    flex-grow: 1;
  }
`

export const MonthsScrollerWrapper = styled.div`
  position: relative;
  flex-grow: 1;
`

export const MonthsScroller = styled.div`
  overflow: auto;
  height: 100%;

  @media only screen and (min-width: ${Breakpoint.sm}px) {
    overflow: hidden;
  }
`

export const TodayButton = styled.button`
  display: none;

  @media only screen and (min-width: ${Breakpoint.sm}px) {
    display: block;

    height: 2rem;
    position: absolute;
    top: 3.125rem;
    left: 0.75rem;

    padding: 0 1rem;

    appearance: none;
    background-color: #f7f7f7;
    border-radius: 4px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

    cursor: pointer;

    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 2rem;
    text-transform: uppercase;
  }
`

export const MonthsWrapper = styled.div<{ width: number, page: number }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  transition: transform 0.25s ease-in-out;

  @media only screen and (min-width: ${Breakpoint.sm}px) {
    flex-direction: row;
    width: ${props => props.width * 100}%;

    ${props => {
      if (!props.page || props.page <= 1) {
        return ''
      }

      let translateX = (props.page - 1) / (CALENDAR_TOTAL_DAYS / CALENDAR_DAYS_PER_VIEW)
      const remainder = (CALENDAR_DAYS_PER_VIEW * CALENDAR_TOTAL_VIEWS) % CALENDAR_TOTAL_DAYS

      if (remainder > 0 && props.page === CALENDAR_TOTAL_VIEWS) {
        // Don't show empty spaces at the end
        translateX -= (remainder / CALENDAR_TOTAL_DAYS)
      }

      return `
        transform: translateX(-${translateX * 100}%);
      `
    }}
  }
`

export const PaginationArrow = styled(PaginationArrowBase)`
  top: ${CALENDAR_MONTH_HEIGHT};
  height: calc(100% - ${CALENDAR_MONTH_HEIGHT});

  ${TodayButton}:hover ~ & > .PaginationArrow__arrow-circle-button {
    opacity: 1;
  }
`
