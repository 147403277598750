import * as React from 'react'

import { CALENDAR_TOTAL_DAYS, CALENDAR_DAYS_PER_VIEW, CALENDAR_TOTAL_VIEWS, CALENDAR_TOTAL_DAYS_MOBILE } from './constants'
import Day from './Day'
import * as GoogleService from '@components/GoogleService'
import Month from './Month'
import PaginationArrow from '@components/PaginationArrow';
import * as Styles from './styles'

import MediaQuery from 'react-responsive'
import { Breakpoint } from '@lib/layout'

const calendarIndexToLinkText = [
  'On events calendar',
  'Spark calendar',
]

export type CalendarEventsByMonth = Array<{
  month: string,
  events: Array<{
    id: string,
    title: string,
    date: Date,
    url: string,
  }>,
}>

const Calendar = () => {
  const [page, setPage] = React.useState(1)

  const cyclePage = (pageNumber: number) => {
    if (pageNumber > CALENDAR_TOTAL_VIEWS) {
      setPage(1)
    } else if (pageNumber < 1) {
      setPage(CALENDAR_TOTAL_VIEWS)
    } else {
      setPage(pageNumber)
    }
  }

  return (
    <Styles.Card>
      <Styles.MonthsScrollerWrapper>
        <Styles.MonthsScroller>
          <Styles.MonthsWrapper width={CALENDAR_TOTAL_DAYS / CALENDAR_DAYS_PER_VIEW} page={page}>
            <MediaQuery maxWidth={Breakpoint.sm -1}>
              {isMobile => (
                <GoogleService.Consumer>
                  {({ calendarEventsByMonthAndDay }) => {
                    if (!calendarEventsByMonthAndDay) { return null }

                    // Limit the number of days shown on mobile
                    let totalDays = 1

                    return calendarEventsByMonthAndDay.map(month => {
                      if (totalDays > CALENDAR_TOTAL_DAYS_MOBILE && isMobile) { return null }
                      return (
                        <Month key={month.month} month={month.month} width={month.days.length / CALENDAR_TOTAL_DAYS}>
                          {month.days.map(day => {
                            if (totalDays > CALENDAR_TOTAL_DAYS_MOBILE && isMobile) { return null }
                            totalDays++
                            return <Day day={day} key={month.month + day.dayOfMonth} />
                          })}
                        </Month>
                      )
                    })
                  }}
                </GoogleService.Consumer>
              )}
            </MediaQuery>
          </Styles.MonthsWrapper>
        </Styles.MonthsScroller>

        <Styles.TodayButton onClick={() => setPage(1)}>
          Today
        </Styles.TodayButton>

        <Styles.PaginationArrow which="left" currentPage={page} cyclePage={cyclePage} />
        <Styles.PaginationArrow which="right" currentPage={page} cyclePage={cyclePage} />
      </Styles.MonthsScrollerWrapper>

      {process.env.GATSBY_GOOGLE_CALENDAR_ID && process.env.GATSBY_GOOGLE_CALENDAR_ID.split(',').map((calendarId, index) => (
        <Styles.ViewMoreLink
          href={`https://calendar.google.com/calendar/embed?src=${calendarId}`}
          target="_blank"
          rel="noopener"
          calendarID={calendarId}
          key={calendarId}
        >
          {calendarIndexToLinkText[index]}
        </Styles.ViewMoreLink>
      ))}
    </Styles.Card>
  )
}

export default Calendar
