import styled from 'styled-components'

import { Card as CardBase, CardTitle as CardTitleBase } from '@lib/layout'
import { HAIRLINE } from '@lib/utilities'

export const Card = styled(CardBase)`
  flex-grow: 1;
  padding: 1rem 0 1rem 1.5rem;
`

export const CardTitle = styled(CardTitleBase)`
  margin-bottom: 1.75rem;
`

export const Ul = styled.ul`
  max-height: 400px;
  overflow: auto;
  padding-right: 1.5rem;
`

export const Li = styled.li`
  border-bottom: ${HAIRLINE};
  overflow: hidden;

  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
`

export const A = styled.a`
  display: flex;
  padding-top: 0.75rem;
  width: 100%;
`

export const Headline = styled.p`
  font-size: 0.875rem;
  margin-bottom: 0.125rem;
  height: 1rem;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Details = styled.time`
  color: #808080;
  font-size: 0.75rem;
  line-height: 1.0625rem;
`

export const Text = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
`
