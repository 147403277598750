import React from 'react'

import * as Styles from './styles'

const TOC: React.FC<{ headings: string[] }> = ({ headings }) => (
  <Styles.Ul>
    {headings.map((heading, index) => (
      <Styles.Li key={index}>
        <a href={`#toc-heading-${index + 1}`}>{heading}</a>
      </Styles.Li>
    ))}
  </Styles.Ul>
)

export default TOC
