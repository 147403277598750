import React, { useState } from 'react'

import MarkdownModalContent from '@components/Dashboard/MarkdownModalContent'
import Modal from '@components/Modal'
import * as Styles from './styles'

const Notice: React.FC<{
  message: string
  modalContent?: string
  headings?: string[]
}> = ({ message, modalContent, headings }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onClick = () => {
    if (modalContent) {
      setIsModalOpen(true)
    }
  }

  return (
    <>
      {modalContent && headings && isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <MarkdownModalContent source={modalContent} headings={headings} />
        </Modal>
      )}

      <Styles.Root onClick={onClick} modalContentExists={!!modalContent}>
        <Styles.CardTitle>{message}</Styles.CardTitle>
      </Styles.Root>
    </>
  )
}

export default Notice
