import * as React from 'react'

import * as Styles from './styles'

const Image: React.FunctionComponent<{ photoURL: string }> = ({ photoURL }) => {
  const style = { backgroundImage: `url("${photoURL}")` }
  return <Styles.ImageDiv style={style} />
}

export default Image
