import styled from 'styled-components'

// We use a div, rather than an img, so that we can use
// `background-size: cover`.
export const ImageDiv = styled.div`
  background-color: #ccc;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;

  width: 48px;
  height: 48px;

  margin-right: 1.25rem;
  margin-bottom: 1rem;
  flex-shrink: 0;
`
