import styled from 'styled-components'

import { Card as CardBase, Breakpoint } from '@lib/layout'
import { VISIBLE_TOOLS_PER_ROW } from './constants';

const TOOL_WIDTH_MD = 6
const TOOL_WIDTH_XL = 7.5

export const Card = styled(CardBase)`
  padding: 1rem 0;
  position: relative;

  @media only screen and (min-width: ${Breakpoint.md}px) {
    padding: 1rem 2rem;
  }
`

export const ScrollWrapper = styled.div`
  @media only screen and (min-width: ${Breakpoint.md}px) {
    margin: 0 auto;
    overflow: hidden;
    width: ${TOOL_WIDTH_MD * VISIBLE_TOOLS_PER_ROW}rem;
  }

  @media only screen and (min-width: ${Breakpoint.xl}px) {
    width: ${TOOL_WIDTH_XL * VISIBLE_TOOLS_PER_ROW}rem;
  }
`

export const Tools = styled.div<{ toolsPerRow: number, page: number }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  transition: transform 0.25s ease-in-out;

  @media only screen and (min-width: ${Breakpoint.md}px) {
    justify-content: flex-start;
    width: ${props => TOOL_WIDTH_MD * props.toolsPerRow}rem;

    ${props => props.page > 1 ? `
      transform: translateX(-${TOOL_WIDTH_MD * VISIBLE_TOOLS_PER_ROW}rem);
    ` : ''}
  }

  @media only screen and (min-width: ${Breakpoint.xl}px) {
    width: ${props => TOOL_WIDTH_XL * props.toolsPerRow}rem;

    ${props => props.page > 1 ? `
      transform: translateX(-${TOOL_WIDTH_XL * VISIBLE_TOOLS_PER_ROW}rem);
    ` : ''}
  }
`

export const Tool = styled.div<{ toolsPerRow: number }>`
  @media only screen and (min-width: ${Breakpoint.md}px) {
    justify-content: flex-start;
    width: ${props => (1 / props.toolsPerRow) * 100}%;
  }
`

export const PageButtons = styled.div`
  display: none;

  @media only screen and (min-width: ${Breakpoint.md}px) {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
  }
`

export const PageButton = styled.button<{ isActive: boolean }>`
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;

  width: 0.875rem;
  height: 0.875rem;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    display: block;
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    background: ${props => props.isActive ? '#999' : '#ccc'};
    border-radius: 50%;
  }
`
