import * as React from 'react'

import * as Styles from './styles'

interface Props {
  month: string
  width: number
  children: React.ReactNode
}

const Month = ({ month, width, children }: Props) => (
  <Styles.Root width={width}>
    <Styles.H2>{month}</Styles.H2>
    <Styles.ChildrenWrapper>{children}</Styles.ChildrenWrapper>
  </Styles.Root>
)

export default Month
