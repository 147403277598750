import React from 'react'

import * as Styles from './styles'
import useKeyboardEvent from '@lib/useKeyboardEvent'

const Modal: React.FC<{ onClose: () => void }> = ({ children, onClose }) => {
  useKeyboardEvent('Escape', onClose)

  return (
    <Styles.Overlay>
      <Styles.Modal>
        {children}
        <Styles.CloseButton onClick={onClose} />
      </Styles.Modal>
    </Styles.Overlay>
  )
}

export default Modal
