import * as React from 'react'

import { formatNumber, formatPercentage } from './helpers'
import ProgressBar from './ProgressBar'
import { Report } from './types'
import * as Styles from './styles'

interface Props {
  reports: Report[]
}

const CompanyTargets = ({ reports }: Props) => (
  <Styles.Card>
    <Styles.CardTitle>Company Goals {new Date().getFullYear()}</Styles.CardTitle>

    <Styles.ContentWrapper>
      {reports.map(report => (
        <div key={report.title}>
          <Styles.TitleAndTotal
            href={report.link}
            target="_blank"
            rel="noopener"
          >
            <Styles.Title>{report.title}</Styles.Title>
            <Styles.Total>{formatNumber(report.yearToDate)}</Styles.Total>
          </Styles.TitleAndTotal>

          <ProgressBar key={report.title} goal={report.yearTarget} progress={report.yearToDate} />

          <Styles.ReportBottomRow>
            <Styles.ActionLinks>
              {report.actions && report.actions.map(action => (
                <Styles.ActionLink
                  href={action.link}
                  target="_blank"
                  rel="noopener"
                >
                  {action.text}
                </Styles.ActionLink>
              ))}
            </Styles.ActionLinks>
            <Styles.Completion>
              {formatPercentage(report.yearToDate, report.yearTarget)} of {formatNumber(report.yearTarget)} {report.targetLabel}
            </Styles.Completion>
          </Styles.ReportBottomRow>
        </div>
      ))}
    </Styles.ContentWrapper>

    <Styles.ViewMoreLinkWrapper>
      <Styles.ViewMoreLink
        href="https://onrunning.eu.looker.com/dashboards/521"
        target="_blank"
        rel="noopener"
      >
        View more on Looker
      </Styles.ViewMoreLink>
    </Styles.ViewMoreLinkWrapper>
  </Styles.Card>
)

export default CompanyTargets
