import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import { Report } from './types'
import CompanyTargets from '.'

interface QueryResult {
  allLookerReport: {
    edges: Array<{
      node: Report
    }>
  }
}

const CompanyTargetsContainer = () => (
  <StaticQuery
    query={graphql`
      {
        allLookerReport(sort: { fields: sortOrder }) {
          edges {
            node {
              title
              link
              yearToDate
              yearTarget
              targetLabel
            }
          }
        }
      }
    `}
    render={({ allLookerReport }: QueryResult) => {
      const reports = allLookerReport.edges.map(({ node }) => node)
      return <CompanyTargets reports={reports} />
    }}
  />
)

export default CompanyTargetsContainer
