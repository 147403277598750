import styled from 'styled-components'
import { Card as CardBase, CardTitle as CardTitleBase } from '@lib/layout'

export { ViewMoreLink } from '@lib/typography'

export const Card = styled(CardBase)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem 1.5rem 0;
`

export const CardTitle = styled(CardTitleBase)`
  margin-bottom: 2.625rem;
`

export const Completion = styled.div`
  flex-grow: 1;

  font-size: 0.75rem;
  letter-spacing: 0.3px;
  text-align: right;
`

export const TitleAndTotal = styled.a`
  display: flex;
  margin-bottom: 0.5rem;
  align-items: flex-end;
`

export const Title = styled.h3`
  flex-grow: 1;

  font-size: 0.875rem;
  letter-spacing: 0.3px;
`

export const Total = styled.div`
  font-size: 1.375rem;
  letter-spacing: 0.3px;
`

export const ContentWrapper = styled.div`
  flex-grow: 1;
`

export const ViewMoreLinkWrapper = styled.div`
  margin: 0 -1rem;
`

export const ReportBottomRow = styled.div`
  display: flex;
  align-items: flex-start;

  margin-bottom: 2.25rem;
  margin-top: 0.5rem;
`

export const ActionLinks = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const ActionLink = styled.a`
  color: #2fbf9b;
  font-size: 0.75rem;
  letter-spacing: 0.3px;
  line-height: 1rem;
`
