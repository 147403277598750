import { graphql, StaticQuery } from 'gatsby'
import React, { useState } from 'react'

import CalendarEvent from '../CalendarEvent'
import { Day as DayType } from '@components/GoogleService/types'
import * as Styles from './styles'

interface QueryReponse {
  contentfulConfig: {
    calendarEventsPerDay: number
  },
}

interface Props {
  day: DayType
}

const Day = ({ day }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <StaticQuery
      query={graphql`
        {
          contentfulConfig {
            calendarEventsPerDay
          }
        }
      `}
      render={({ contentfulConfig }: QueryReponse) => {
        const { calendarEventsPerDay = 3 } = contentfulConfig
        const additionalEvents = day.events.length - calendarEventsPerDay
        const events = isExpanded ? day.events : day.events.slice(0, contentfulConfig.calendarEventsPerDay)

        return (
        <Styles.Root>
          <Styles.DayAndDate>
            <Styles.Day>{day.dayOfWeek}</Styles.Day>
            <Styles.Date>{day.dayOfMonth}</Styles.Date>
          </Styles.DayAndDate>

          <Styles.EventsWrapper>
            {events.map(event => (
              <CalendarEvent
                key={event.id}
                title={event.title}
                date={event.date}
                url={event.url}
                allDayEvent={event.allDayEvent}
                calendarID={event.calendarID}
              />
            ))}
            {!isExpanded && additionalEvents > 0 && (
              <Styles.MoreLink onClick={() => setIsExpanded(true)}>
                ...and {additionalEvents} more
              </Styles.MoreLink>
            )}
          </Styles.EventsWrapper>
        </Styles.Root>
      )
      }}
    />
  )
}

export default Day
