import styled from 'styled-components'

export const LoadingImg = styled.img<{ isLoading: boolean }>`
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  transition: opacity 0.35s;
  pointer-events: none;
  z-index: 1;

  /* Render at 50% size to help with aliasing */
  width: 40px;
  height: 40px;

  ${props => !props.isLoading && 'opacity: 0;'}
`

export const HelpDeskWrapper = styled.div`
  max-width: 300px;
`
