import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import * as Styles from './styles'
import Tool from './Tool'
import { VISIBLE_TOOLS_PER_ROW } from './constants';

import PaginationArrow from '@components/PaginationArrow';

interface InternalTool {
  title: string
  description: {
    description: string;
  },
  url: {
    url: string;
  },
  icon?: {
    file: {
      url: string;
    },
  },
}

interface QueryResult {
  contentfulConfig: {
    internalTools: InternalTool[],
  },
}

const query = graphql`
  query InternalToolsConfigQuery {
    contentfulConfig {
      internalTools {
        title
        description {
          description
        }
        url {
          url
        }
        icon {
          file {
            url
          }
        }
      }
    }
  }
`

const InternalToolsPresentationalComponent: React.FC<{ data: QueryResult }> = ({ data }) => {
  const [page, setPage] = React.useState(1)

  const toolsPerRow = Math.ceil(data.contentfulConfig.internalTools.length / 2)
  const totalPages = Math.ceil(toolsPerRow / VISIBLE_TOOLS_PER_ROW)

  const cyclePage = (pageNumber: number) => {
    if (pageNumber > totalPages) {
      setPage(1)
    } else if (pageNumber < 1) {
      setPage(totalPages)
    } else {
      setPage(pageNumber)
    }
  }

  return (
    <Styles.Card>
      <Styles.ScrollWrapper>
        <Styles.Tools toolsPerRow={toolsPerRow} page={page}>
          {data.contentfulConfig.internalTools.map(tool => (
            <Styles.Tool toolsPerRow={toolsPerRow} key={tool.url.url}>
              <Tool
                title={tool.title}
                description={tool.description.description}
                iconURL={tool.icon && tool.icon.file.url}
                url={tool.url.url}
              />
            </Styles.Tool>
          ))}
        </Styles.Tools>
      </Styles.ScrollWrapper>

      <Styles.PageButtons>
        {Array(totalPages).fill(null).map((_, index) => (
          <Styles.PageButton
            isActive={page === (index + 1)}
            onClick={() => setPage(index + 1)}
            aria-label={`Go to page ${index + 1}`}
            key={index}
          />
        ))}
      </Styles.PageButtons>

      <PaginationArrow which="left" currentPage={page} cyclePage={cyclePage} />
      <PaginationArrow which="right" currentPage={page} cyclePage={cyclePage} />
    </Styles.Card>
  )
}

const InternalTools = () => (
  <StaticQuery
    query={query}
    render={(data: QueryResult) => <InternalToolsPresentationalComponent data={data} />}
  />
)


export default InternalTools
