import styled from 'styled-components'

import { Breakpoint } from '@lib/layout';

export const Root = styled.article`
  display: flex;
`

export const TOCWrapper = styled.div`
  display: none;

  @media only screen and (min-width: ${Breakpoint.md}px) {
    display: block;
    flex-shrink: 0;
    padding: 11.5rem 3rem 3rem;
    width: 350px;
  }
`

export const StyledMarkdownWrapper = styled.div`
  padding: 0 2rem 5rem;
  max-width: 600px;

  @media only screen and (min-width: ${Breakpoint.md}px) {
    padding: 0 0 5rem;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }
`
