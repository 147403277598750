import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'
import * as React from 'react'

import DarkBackgroundCSS from '@components/DarkBackgroundCSS'
import LoginButton from './LoginButton'

interface Props {
  setUser: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => Promise<any>
}

const Login = ({ setUser }: Props) => {
  if (!process.env.GATSBY_GOOGLE_CLIENT_ID) { return null }

  return (
    <>
      <DarkBackgroundCSS />
      <GoogleLogin
        clientId={process.env.GATSBY_GOOGLE_CLIENT_ID}
        onSuccess={setUser}
        // No need to do anything in particular other than log the response
        onFailure={console.error} // tslint:disable-line:no-console
        render={renderProps => <LoginButton onClick={renderProps && renderProps.onClick} />}
        isSignedIn={true}
        scope="openid email profile https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.readonly"
      />
    </>
  )
}

export default Login
