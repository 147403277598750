import styled from 'styled-components'

import { Breakpoint } from '@lib/layout';

export const ArrowHoverTarget = styled.div<{ which: 'right' | 'left' }>`
  display: none;
  position: absolute;
  top: 0;
  height: 100%;

  @media only screen and (min-width: ${Breakpoint.sm}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${props => props.which === 'right' ? `
      right: -18px;
    ` : `
      left: -18px;
    `}
  }
`

export const ArrowCircleButton = styled.div`
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  cursor: pointer;

  width: 36px;
  height: 36px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: opacity 0.35s, background-color 0.35s;

  opacity: 0;

  ${ArrowHoverTarget}:hover > & {
    opacity: 1;

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
`

export const Arrow = styled.img`
  display: block;
  width: 6px;
  height: 12px;
`
