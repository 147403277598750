import styled from 'styled-components'

import { Breakpoint } from '@lib/layout'

export const A = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-decoration: none;

  margin: 1rem 1rem;
`

export const H3 = styled.h3`
  font-size: 0.6875rem;
  text-align: center;
`

export const Figure = styled.figure`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
  width: 64px;
  height: 64px;
  padding: 14px;
  margin-bottom: 6px;
  transition: background-color 0.35s;

  ${A}:hover & {
    background-color: white;
  }
`

// We use a div, rather than an img, so that we can use
// `background-size: contain`.
export const ImageDiv = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
`
