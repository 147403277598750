import { Col, Row } from 'react-grid-system'
import MediaQuery from 'react-responsive'
import * as React from 'react'

import { Breakpoint } from '@lib/layout'
import Calendar from '@components/Calendar'
import CompanyTargetsContainer from '@components/CompanyTargets/container'
import DarkBackgroundCSS from '@components/DarkBackgroundCSS'
import HelpDesk from '@components/HelpDesk'
import InternalTools from '@components/InternalTools'
import NewcomersContainer from '@components/Newcomers/container'
import Notices from './Notices'
import * as Styles from './styles'

import loader from './images/loader.gif'

interface Props {
  isLoading: boolean
}

const Dashboard = ({ isLoading }: Props) => (
  <>
    <Styles.LoadingImg src={loader} alt="A loading GIF" isLoading={isLoading} />

    {isLoading && <DarkBackgroundCSS />}

    {!isLoading && (
      <>
        <MediaQuery maxWidth={Breakpoint.md - 1}>
          {matches =>
            matches && (
              <>
                <Notices />
                <InternalTools />
                <Calendar />
                <CompanyTargetsContainer />
                <NewcomersContainer />
                <HelpDesk />
              </>
            )
          }
        </MediaQuery>

        <MediaQuery minWidth={Breakpoint.md} maxWidth={Breakpoint.lg - 1}>
          {matches =>
            matches && (
              <Row>
                <Col
                  sm={10}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Notices />
                  <InternalTools />
                  <Calendar />

                  <Styles.HelpDeskWrapper>
                    <HelpDesk />
                  </Styles.HelpDeskWrapper>
                </Col>

                <Col
                  sm={6}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <CompanyTargetsContainer />
                  <NewcomersContainer />
                </Col>
              </Row>
            )
          }
        </MediaQuery>

        <MediaQuery minWidth={Breakpoint.lg}>
          {matches =>
            matches && (
              <Row>
                <Col
                  sm={4}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <NewcomersContainer />
                  <HelpDesk />
                </Col>

                <Col
                  sm={8}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Notices />
                  <InternalTools />
                  <Calendar />
                </Col>

                <Col
                  sm={4}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <CompanyTargetsContainer />
                </Col>
              </Row>
            )
          }
        </MediaQuery>
      </>
    )}
  </>
)

export default Dashboard
