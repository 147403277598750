import styled from 'styled-components'

import { Breakpoint } from '@lib/layout'
import { HAIRLINE } from '@lib/utilities'

export const Root = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  margin-bottom: 1rem;

  border-bottom: 2px solid rgba(0, 0, 0, 0.15);

  &:last-child {
    border-bottom: none;
    border-right: none;
    margin-bottom: 0;
  }

  @media only screen and (min-width: ${Breakpoint.sm}px) {
    border-bottom: none;
    border-right: ${HAIRLINE};
    margin-bottom: 0;
    width: ${props => props.width * 100}%;
  }
`

export const ChildrenWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  @media only screen and (min-width: ${Breakpoint.sm}px) {
    flex-direction: row;
  }
`

export const H2 = styled.h2`
  height: 2.5rem;
  padding: 0 0.75rem;

  border-bottom: ${HAIRLINE};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 2.5rem;
`
