import { graphql, StaticQuery } from 'gatsby'
import React from 'react'

import Notice from '@components/Notice'

interface StaticQueryResult {
  notices: {
    edges: Array<{
      node: {
        id: string
        message: string
        modalContent: {
          modalContent: string
          childMarkdownRemark: {
            headings: Array<{
              value: string
              depth: number
            }>
          }
        }
      }
    }>
  }
}

const Notices: React.FC<{}> = () => (
  <StaticQuery
    query={graphql`
      {
        notices: allContentfulNotice(filter: { active: { eq: true } }) {
          edges {
            node {
              id: contentful_id
              message
              modalContent {
                modalContent
                childMarkdownRemark {
                  headings {
                    value
                    depth
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ notices }: StaticQueryResult) => (
      <>
        {notices &&
          notices.edges.map(({ node }) => (
            <Notice
              key={node.id}
              message={node.message}
              modalContent={
                node.modalContent ? node.modalContent.modalContent : undefined
              }
              headings={
                node.modalContent
                  ? node.modalContent.childMarkdownRemark.headings
                      .filter(({ depth }) => depth === 2)
                      .map(({ value }) => value)
                  : undefined
              }
            />
          ))}
      </>
    )}
  />
)

export default Notices
