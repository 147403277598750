import * as React from 'react'

import Image from './Image'
import { Newcomer } from './types'
import * as Styles from './styles'

interface Props {
  newcomers: Newcomer[]
}

const Newcomers = ({ newcomers }: Props) => (
  <Styles.Card>
    <Styles.CardTitle>New On Team Members</Styles.CardTitle>
    <Styles.Ul>
      {newcomers.map(({ id, photoURL, photoMimeType, name, details, slackLink }) => (
        <Styles.Li key={id}>
          <Styles.A href={slackLink} target="_blank" rel="noopener">
            <Image photoURL={photoURL} photoMimeType={photoMimeType} />
            <Styles.Text>
              <Styles.Headline>{name}</Styles.Headline>
              <Styles.Details>{details}</Styles.Details>
            </Styles.Text>
          </Styles.A>
        </Styles.Li>
      ))}
    </Styles.Ul>
  </Styles.Card>
)

export default Newcomers
