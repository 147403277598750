import styled, { css } from 'styled-components'

import { FontFamily, FontWeight } from '@lib/typography'

const BaseText = css`
  color: #333;
  font-size: 0.875rem;
  line-height: 1.25rem;
`

export const H1 = styled.h1`
  color: #333;
  font-family: ${FontFamily.Secondary};
  font-size: 3.25rem;
  letter-spacing: -0.2px;

  margin-bottom: 5.75rem;
`

export const H2 = styled.h2`
  color: #333;
  font-family: ${FontFamily.Secondary};
  font-size: 2rem;

  margin-bottom: 3rem;
  margin-top: 6rem;

  scroll-margin-top: 2rem;
`

export const H3 = styled.h3`
  color: #333;
  font-weight: ${FontWeight.Book};
  font-size: 0.75rem;
  letter-spacing: 3px;
  line-height: 1.25rem;
  text-transform: uppercase;

  margin-bottom: 1.65rem;
  margin-top: 3.5rem;
`

export const Ul = styled.ul`
  list-style-type: disc;
`

export const Li = styled.li`
  ${BaseText}

  margin-bottom: 1rem;
  margin-left: 1rem;
`

export const Table = styled.table`
  width: 100%;
`

export const TableCell = styled.td`
  ${BaseText}

  border-bottom: 1px solid #dfdfdf;
  padding: 0.75rem 0;

  &:first-child {
    color: #808080;
    padding-right: 0.375rem;
  }

  &:last-child {
    padding-left: 0.375rem;
  }
`

export const P = styled.p`
  ${BaseText}

  margin-bottom: 1rem;
`

export const A = styled.a`
  ${BaseText}
  color: #0000EE;
  margin-bottom: 1rem;
`
