import styled, { keyframes } from 'styled-components'

const expandRight = keyframes`
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
`

export const Progress = styled.div<{ width: number }>`
  animation: 1s ${expandRight} ease-in-out;
  transform-origin: 0 0;

  background: #23D9AC;
  height: 4px;

  width: ${props => props.width}%;
`

export const Track = styled.div`
  background: white;
  height: 4px;
`
