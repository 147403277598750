import React from 'react'

import StyledMarkdown from './StyledMarkdown'
import * as Styles from './styles'
import TOC from './TOC'

const MarkdownModalContent: React.FC<{
  source: string
  headings: string[]
}> = ({ source, headings }) => (
  <Styles.Root>
    <Styles.TOCWrapper>
      <TOC headings={headings} />
    </Styles.TOCWrapper>

    <Styles.StyledMarkdownWrapper>
      <StyledMarkdown source={source} />
    </Styles.StyledMarkdownWrapper>
  </Styles.Root>
)

export default MarkdownModalContent
