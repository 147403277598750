import * as React from 'react'

import * as Styles from './styles'

interface Props {
  onClick?: () => void
}

const LoginButton = ({ onClick }: Props) => <Styles.Span onClick={onClick}>Log in with Google</Styles.Span>

export default LoginButton
