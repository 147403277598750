import styled, { css } from "styled-components";

import { CardCSS, CardTitle } from '@lib/layout'
export { CardTitle } from '@lib/layout'

export const Root = styled.article<{ modalContentExists: boolean }>`
  ${CardCSS}

  background: white;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  text-align: center;

  ${props => props.modalContentExists ? css`
    cursor: pointer;

    > ${CardTitle} {
      transition: color 0.25s;
    }

    &:hover > ${CardTitle} {
      color: #222;
    }
  ` : ``}
`
