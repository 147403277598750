import styled from 'styled-components'

import { Breakpoint } from '@lib/layout'
import { CalendarDot } from '../styles'

export const A = styled.a<{ calendarID: string }>`
  display: flex;
  align-items: flex-start;

  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  ${CalendarDot}

  &::before {
    margin-top: 5px;
  }

  @media only screen and (min-width: ${Breakpoint.sm}px) {
    flex-direction: row;
    margin-bottom: 0.75rem;
  }
`

export const Title = styled.div`
  font-size: 0.875rem;
  line-height: 1rem;
  flex-grow: 1;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  margin-bottom: 0.25rem;

  @media only screen and (min-width: ${Breakpoint.sm}px) {
    margin-bottom: 0;
  }
`

export const Time = styled.time`
  font-size: 0.75rem;
  line-height: 1.25rem;
`

export const TextWrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
`
