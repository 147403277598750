import styled from 'styled-components'

export const Span = styled.div`
  position: absolute;
  top: 50vh;
  transform: translateY(-50%);
  width: 100%;

  color: white;
  cursor: pointer;
  font-family: 'Neutraface Slab';
  font-size: 1.25rem;
  text-align: center;
`
