import styled from 'styled-components'

import { Breakpoint } from '@lib/layout';
import closeButton from './closeButton.svg'
import closeButtonHover from './closeButtonHover.svg'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(0, 0, 0, 0.5);

  overflow: auto;
  scroll-behavior: smooth;

  z-index: 1;

  @media only screen and (min-width: ${Breakpoint.md}px) {
    padding: 1.5rem;
  }
`

export const Modal = styled.section`
  background: white;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${Breakpoint.md}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const CloseButton = styled.button`
  background-color: transparent;
  background-image: url("${closeButton}");
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;

  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;

  position: sticky;
  top: 1rem;

  order: -1;

  align-self: flex-end;
  margin: 1rem 1rem 1rem 0;

  &::after {
    content: '';
    display: block;

    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background-image: url("${closeButtonHover}");
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0;
    transition: opacity 0.25s;
  }

  &:hover::after {
    opacity: 1;
  }

  @media only screen and (min-width: ${Breakpoint.md}px) {
    order: 1;
    align-self: flex-start;

    margin-top: 3.25rem;
    margin-right: 3.25rem;
  }
`
