import * as React from 'react'

import { Context as GoogleServiceContext } from '@components/GoogleService'
import { Newcomer } from './types'
import Newcomers from '.'

const getProxyURLForPhoto = (photoURL: string): string => {
  const url = new URL(photoURL)
  return `/slack-file${url.pathname}`
}

// Slack messages may contain HTML entities. To decode them, we'll create a
// textarea element and set its `innerHTML` to the message, then get its value
// back. See https://stackoverflow.com/a/42182294/974981. (We'll wrap this in an
// IIFE to avoid recreating the HTML element every time.)
const decodeHTMLEntities = (() => {
  if (typeof window === 'undefined') {
    return () => ''
  }

  const textArea = window.document.createElement('textarea')

  return (message: string): string => {
    textArea.innerHTML = message
    return textArea.value
  }
})()

// This appears to be the correct way to get a link to a Slack message without
// having to use the `chat.getPermalink` method
// (https://api.slack.com/methods/chat.getPermalink).
const getSlackLink = (ts: string): string =>
  `https://onrunning.slack.com/archives/${process.env.GATSBY_SLACK_TALENT_UPDATES_CHANNEL}/p${ts.replace('.', '')}`

const NewcomersContainer = () => {
  const [newcomers, setNewcomers] = React.useState<Newcomer[]>([])
  const { getUser } = React.useContext(GoogleServiceContext)

  React.useEffect(() => {
    const user = getUser()
    const fetchMessages = async () => {
      if (!user) { return }

      try {
        const limit = 50 // to make sure we get enough messages that have photos after filtering below
        const channel = process.env.GATSBY_SLACK_TALENT_UPDATES_CHANNEL
        const { idToken } = user
        const url = `/.netlify/functions/slack?limit=${limit}&channel=${channel}&idToken=${idToken}`
        const response = await fetch(url)
        const { messages } = await response.json()

        const messagesWithFiles = messages.filter((message: any) => {
          return message.files && message.files.length && message.files[0].url_private_download
        })

        const fetchedNewcomers = messagesWithFiles.map((message: any) => {
          const decodedMessage = decodeHTMLEntities(message.text)
          let firstLine = decodedMessage.split('\n')[0]
          firstLine = firstLine.replace(/^\*/, '').replace(/\*$/, '') // Get rid of Markdown formatting
          const [name, ...details] = firstLine.split('/')

          const file = message.files[0]

          return {
            id: message.ts,
            photoURL: getProxyURLForPhoto(file.url_private_download),
            name,
            details: details.join('/'),
            slackLink: getSlackLink(message.ts),
          }
        })

        setNewcomers(fetchedNewcomers)
      } catch (e) {
        console.error(e)
      }
    }

    try {
      fetchMessages()
    } catch (e) {
      console.error(e)
    }
  }, [])

  return (
    <Newcomers newcomers={newcomers} />
  )
}

export default NewcomersContainer
