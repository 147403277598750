import styled from 'styled-components'

import { Breakpoint } from '@lib/layout'
import { HAIRLINE } from '@lib/utilities'

export const Root = styled.div`
  display: flex;
  flex-direction: row;

  flex: 1;
  min-width: 0;
  padding: 0.75rem 1.5rem 1.75rem;

  border-bottom: ${HAIRLINE};

  &:last-child {
    border-bottom: none;
    border-right: none;
  }

  @media only screen and (min-width: ${Breakpoint.sm}px) {
    border-bottom: none;
    border-right: ${HAIRLINE};
    flex-direction: column;
    padding: 0.75rem 0.75rem 1.75rem;
  }
`

export const DayAndDate = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 1.5rem;
  margin-right: 2rem;

  @media only screen and (min-width: ${Breakpoint.sm}px) {
    flex-direction: row;
    margin-right: 0;
  }
`

export const Day = styled.div`
  padding-bottom: 0.5rem;
  width: 2rem;

  color: #999;
  font-size: 0.75rem;
  text-align: center;
  text-transform: uppercase;

  @media only screen and (min-width: ${Breakpoint.sm}px) {
    /* To align with date */
    padding-top: 0.25rem;

    flex-grow: 1;
    text-align: left;
    width: auto;
  }
`

export const Date = styled.div`
  font-size: 1.75rem;
  text-align: center;
`

export const EventsWrapper = styled.div`
  flex-grow: 1;
`

export const MoreLink = styled.div`
  cursor: pointer;
  font-size: 0.75rem;
  line-height: 1rem;
`
