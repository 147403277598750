import * as React from 'react'

import * as Styles from './styles'

interface Props {
  title: string
  description?: string
  url: string
  iconURL?: string
}

const Tool = ({ title, description, url, iconURL }: Props) => (
  <Styles.A
    href={url}
    target="_blank"
    rel="noopener"
    title={description}
  >
    {iconURL && (
      <Styles.Figure>
        <Styles.ImageDiv style={{ backgroundImage: `url(${iconURL})` }} />
      </Styles.Figure>
    )}
    <Styles.H3>{title}</Styles.H3>
  </Styles.A>
)

export default Tool
