import { useEffect } from "react";

// Source: https://medium.com/@nicolaslopezj/reusing-logic-with-react-hooks-8e691f7352fa
const useKeyboardEvent = (key: string, callback: () => void) => {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === key) {
        callback()
      }
    }

    window.addEventListener('keydown', handler)

    return () => {
      window.removeEventListener('keydown', handler)
    }
  }, [])
}

export default useKeyboardEvent
