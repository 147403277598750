import * as React from 'react'

import * as Styles from './styles'

interface Props {
  goal: number
  progress: number
}

const ProgressBar = ({ goal, progress }: Props) => (
  <Styles.Track>
    <Styles.Progress width={Math.min(100, (progress / goal) * 100)} />
  </Styles.Track>
)

export default ProgressBar
