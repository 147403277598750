import * as React from 'react'

import * as Styles from './styles'
import { ViewMoreLink } from '@lib/typography'

const HelpDesk = () => (
  <Styles.Card>
    <Styles.P>Need help or want to report a bug?</Styles.P>

    <ViewMoreLink
      href="https://onrunning.atlassian.net/servicedesk/customer/portal/19"
      target="_blank"
      rel="noopener"
    >
      IT help desk
    </ViewMoreLink>

    <ViewMoreLink
      href="https://onrunning.atlassian.net/servicedesk/customer/portal/10"
      target="_blank"
      rel="noopener"
    >
      Finance help desk
    </ViewMoreLink>

    <ViewMoreLink
      href="https://onrunning.atlassian.net/servicedesk/customer/portal/20"
      target="_blank"
      rel="noopener"
    >
      Talent help desk
    </ViewMoreLink>
    
    <ViewMoreLink
      href="https://onrunning.atlassian.net/servicedesk/customer/portal/14"
      target="_blank"
      rel="noopener"
    >
      HD help desk
    </ViewMoreLink>
    
    <ViewMoreLink
      href="https://onrunning.atlassian.net/servicedesk/customer/portal/15"
      target="_blank"
      rel="noopener"
    >
      Supply Chain Help Desk
    </ViewMoreLink>
    
    <ViewMoreLink
      href="https://onrunning.atlassian.net/servicedesk/customer/portal/17"
      target="_blank"
      rel="noopener"
    >
      Workspace Facility Management Help Desk
    </ViewMoreLink>

    <ViewMoreLink
      href="https://app.smartsheet.com/b/form/76ded41f8eaa4e169817f42e56b298bb"
      target="_blank"
      rel="noopener"
    >
      Retail Facility Management Help Desk
    </ViewMoreLink>
    
  </Styles.Card>
)

export default HelpDesk
