import styled from "styled-components";

export const Ul = styled.ul`
  position: sticky;
  top: 1rem;
  margin-top: 1rem;
`

export const Li = styled.li`
  color: #808080;
  margin-bottom: 2rem;
`
