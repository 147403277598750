import { createGlobalStyle } from 'styled-components'

const DarkBackgroundCSS = createGlobalStyle`
  body::before {
    filter: blur(5px);
    opacity: 0.5;
  }
`

export default DarkBackgroundCSS
